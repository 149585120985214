import axios from "axios";
import * as util from "./util";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const protectedApi = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});
protectedApi.interceptors.request.use((config) => {
  const token = util.getToken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

const x = { api, protectedApi };
export default x;
