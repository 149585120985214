import React, { useState } from "react";
import { api } from "../api";
import { useNavigate } from "react-router-dom";

const Login = ({ setToken, setLoading }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const url = isRegistering ? "/register" : "/login";
      const response = await api.post(url, { username, password });
      setToken(response.data.token);
      navigate("/protected");
    } catch (error) {
      console.error(error);
      alert(`${isRegistering ? "Registration" : "Login"} failed.`);
    }
    setLoading(false);
  };

  return (
    <div className="w-full max-w-md mx-auto px-6">
      <h2 className="text-2xl font-bold mb-4">
        {isRegistering ? "Register" : "Login"}
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block mb-1">Username</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-1">Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <button
          type="submit"
          className="w-full py-2 px-4 bg-blue-500 text-white rounded"
        >
          {isRegistering ? "Register" : "Login"}
        </button>
      </form>
      {/* <div className="mt-4">
        <button
          onClick={() => setIsRegistering(!isRegistering)}
          className="text-blue-500 hover:underline"
        >
          {isRegistering
            ? "Already have an account? Login here."
            : "No account? Register here."}
        </button>
      </div> */}
    </div>
  );
};

export default Login;
