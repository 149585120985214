import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { protectedApi } from "../api";

const groupFeedsByDate = (feeds) => {
  const grouped = feeds.reduce((acc, feed) => {
    const date = new Date(feed.timestamp * 1000);
    const formattedDate = `${date.getMonth() + 1}/${date.getDate()}`;
    if (!acc[formattedDate]) {
      acc[formattedDate] = { totalMilliliters: 0, feeds: [] };
    }
    acc[formattedDate].totalMilliliters += feed.milliliters;
    acc[formattedDate].feeds.push(feed);
    return acc;
  }, {});

  return Object.entries(grouped).sort(
    (a, b) => new Date(b[0]) - new Date(a[0])
  );
};

const FeedList = ({ setLoading, loading }) => {
  const [feeds, setFeeds] = useState([]);
  const [startPoint, setStartPoint] = useState(0);
  const [pullChange, setPullChange] = useState();
  const [initialLoad, setInitialLoad] = useState(false);
  const [refreshLoad, setRefreshLoad] = useState(false);
  const navigate = useNavigate();

  const pullStart = (e) => {
    const { screenY } = e.targetTouches[0];
    setStartPoint(screenY);
  };

  const pull = (e) => {
    /**
     * get the current user touch event data
     */
    const touch = e.targetTouches[0];
    /**
     * get the touch position on the screen's Y axis
     */
    const { screenY } = touch;
    /**
     * The length of the pull
     *
     * if the start touch position is lesser than the current touch position, calculate the difference, which gives the `pullLength`
     *
     * This tells us how much the user has pulled
     */
    let pullLength = startPoint < screenY ? Math.abs(screenY - startPoint) : 0;
    setPullChange(pullLength);
    console.log({ screenY, startPoint, pullLength, pullChange });
  };

  const endPull = (e) => {
    setStartPoint(0);
    setPullChange(0);
    if (pullChange > 220) setRefreshLoad(true);
  };

  useEffect(() => {
    window.addEventListener("touchstart", pullStart);
    window.addEventListener("touchmove", pull);
    window.addEventListener("touchend", endPull);
    return () => {
      window.removeEventListener("touchstart", pullStart);
      window.removeEventListener("touchmove", pull);
      window.removeEventListener("touchend", endPull);
    };
  });

  useEffect(() => {
    const fetchFeeds = async () => {
      setInitialLoad(true);
      setLoading(true);
      try {
        const response = await protectedApi.get("/feed");
        const sortedFeeds = response.data.sort(
          (a, b) => b.timestamp - a.timestamp
        );
        setFeeds(sortedFeeds);
      } catch (error) {
        navigate("/login");
        console.error("Failed to fetch feeds:", error);
      }
      setLoading(false);
    };

    if (!initialLoad || refreshLoad) {
      fetchFeeds();
      setRefreshLoad(false);
    }
  }, [navigate, setLoading, initialLoad, refreshLoad]);

  const handleEdit = (feedID) => {
    navigate(`/edit-feed/${feedID}`);
  };

  let lastDate = "";
  const groupedFeeds = groupFeedsByDate(feeds);

  return (
    <div className="overflow-x-auto min-h-[calc(100vh-64px)]">
      {!loading && (
        <table className="min-w-full border-collapse table">
          <thead className="table-header-group">
            <tr className="border border-gray-300 border-none table-row relative">
              <th className="bg-gray-100 p-2 text-left table-cell">Date</th>
              <th className="bg-gray-100 p-2 text-left table-cell">Time</th>
              <th className="bg-gray-100 p-2 text-left table-cell">
                Milliliters
              </th>
              <th className="bg-gray-100 p-2 text-left table-cell"></th>
            </tr>
          </thead>
          <tbody className="table-row-group">
            {groupedFeeds.map(([date, data]) => {
              return (
                <React.Fragment key={date}>
                  {data.feeds.map((feed) => {
                    const currentDate = date;
                    const showDate = currentDate !== lastDate;
                    lastDate = currentDate;
                    return (
                      <tr
                        key={feed.id}
                        className="border border-gray-300 border-none table-row"
                      >
                        <td className="p-2 text-left table-cell">
                          {showDate ? currentDate : ""}
                        </td>
                        <td className="p-2 text-left table-cell">
                          {new Date(feed.timestamp * 1000).toLocaleTimeString(
                            [],
                            { hour: "2-digit", minute: "2-digit" }
                          )}
                        </td>
                        <td className="p-2 text-left table-cell">
                          {feed.milliliters}
                        </td>
                        <td className="p-2 text-left table-cell">
                          <button
                            onClick={() => handleEdit(feed.id)}
                            className="px-2 py-1 bg-blue-500 text-white rounded mr-2"
                          >
                            Edit
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                  <tr className="border border-gray-300 border-none table-row">
                    <td className="p-2 text-left table-cell" colSpan="2"></td>
                    <td className="p-2 text-left table-cell font-bold">
                      {(data.totalMilliliters * 0.033814).toFixed(2)} oz
                    </td>
                    <td className="p-2 text-left table-cell"></td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default FeedList;
