import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { protectedApi } from "../api";
import * as util from "../util";

const EditFeed = ({ setLoading }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [milliliters, setMilliliters] = useState("");
  const [timestamp, setTimestamp] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchFeed = async () => {
      try {
        const response = await protectedApi.get(`/feed/${id}`);
        setMilliliters(response.data.milliliters);
        setTimestamp(
          util.convertTimestampToDatetimeLocal(response.data.timestamp)
        );
      } catch (error) {
        console.error(error);
        setMessage("Failed to load feed.");
      }
      setLoading(false);
    };

    fetchFeed();
  }, [id, setLoading]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setLoading(true);
    try {
      const parsedTimestamp = timestamp
        ? Math.floor(new Date(timestamp).getTime() / 1000)
        : Math.floor(Date.now() / 1000);
      const parsedMilliliters = parseInt(milliliters);

      await protectedApi.put(`/feed/${id}`, {
        milliliters: parsedMilliliters,
        timestamp: parsedTimestamp,
      });
      navigate("/protected");
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      setMessage("Failed to update feed.");
    }
    setLoading(false);
  };

  const onCancel = () => {
    navigate("/protected");
  };

  const handleDelete = async (feedID) => {
    if (window.confirm("Are you sure you want to delete this feed?")) {
      try {
        await protectedApi.delete(`/feed/${feedID}`);
        navigate("/protected");
      } catch (error) {
        setMessage("Failed to delete feed.");
        console.error("Failed to delete feed:", error);
      }
    }
  };

  return (
    <div className="w-full max-w-md mx-auto px-6">
      <h2 className="text-2xl font-bold mb-4">Edit Feed</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block mb-1">Milliliters</label>
          <input
            type="number"
            inputMode="numeric"
            value={milliliters}
            onChange={(e) => setMilliliters(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            // autoFocus="true"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block mb-1">Timestamp</label>
          <input
            type="datetime-local"
            value={timestamp}
            onChange={(e) => setTimestamp(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <button
          type="submit"
          className="w-full py-2 px-4 bg-blue-500 text-white rounded mb-4"
          disabled={isSubmitting}
        >
          Save Changes
        </button>
        <button
          className="w-full py-2 px-4 bg-white text-blue-500 rounded mb-16"
          onClick={onCancel}
          disabled={isSubmitting}
        >
          Cancel
        </button>
      </form>
      <button
        onClick={() => handleDelete(id)}
        className="w-full py-2 px-4 bg-white text-red-500 rounded"
      >
        Delete
      </button>
      {message && <p className="mt-4">{message}</p>}
    </div>
  );
};

export default EditFeed;
