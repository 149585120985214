import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { protectedApi } from "../api";
import * as util from "../util";

const AddFeed = ({ setLoading }) => {
  const navigate = useNavigate();
  const [milliliters, setMilliliters] = useState("");
  const [timestamp, setTimestamp] = useState(
    util.convertTimestampToDatetimeLocal(new Date().getTime() / 1000)
  );
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setLoading(true);
    try {
      const parsedTimestamp = timestamp
        ? Math.floor(new Date(timestamp).getTime() / 1000)
        : Math.floor(Date.now() / 1000);

      await protectedApi.post("/feed", {
        milliliters: parseInt(milliliters),
        timestamp: parsedTimestamp,
      });
      navigate("/protected");
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      setMessage("Failed to add feed.");
    }
    setLoading(false);
  };

  const onCancel = () => {
    navigate("/protected");
  };

  return (
    <div className="w-full max-w-md mx-auto px-6">
      <h2 className="text-2xl font-bold mb-4">Add Feed</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block mb-1">Milliliters</label>
          <input
            type="number"
            inputMode="numeric"
            value={milliliters}
            onChange={(e) => setMilliliters(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            // autoFocus="true"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block mb-1">Timestamp</label>
          <input
            type="datetime-local"
            value={timestamp}
            onChange={(e) => setTimestamp(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>
        <button
          type="submit"
          className="w-full py-2 px-4 bg-blue-500 text-white rounded mb-4"
          disabled={isSubmitting}
        >
          Add Feed
        </button>
        <button
          className="w-full py-2 px-4 bg-white text-blue-500 rounded"
          onClick={onCancel}
          disabled={isSubmitting}
        >
          Cancel
        </button>
      </form>
      {message && <p className="mt-4">{message}</p>}
    </div>
  );
};

export default AddFeed;
