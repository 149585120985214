import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Login from "./components/Login";
import Protected from "./components/Protected";
import AddFeed from "./components/AddFeed";
import EditFeed from "./components/EditFeed";
import { useNavigate } from "react-router-dom";
import loaderSVG from "./loader.svg";
import * as util from "./util";

function Root() {
  const navigate = useNavigate();
  useEffect(() => {
    const token = util.getToken();
    if (token) {
      navigate("/protected");
    } else {
      navigate("/login");
    }
  }, [navigate]);
  return <></>;
}

function App() {
  return (
    <Router>
      <AppBody />
    </Router>
  );
}

function AppBody() {
  const [token, setToken] = useState(null);
  const [logout, setLogout] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const savedToken = util.getToken();
    if (savedToken) {
      setToken(savedToken);
    }
  }, []);

  useEffect(() => {
    if (logout) {
      handleSetToken(null);
      navigate("/login");
      setLogout(false);
    }
  }, [logout, navigate]);

  const handleSetToken = (newToken) => {
    if (newToken) {
      util.setToken(newToken);
      setToken(newToken);
    } else {
      util.removeToken();
      setToken(null);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <header className="bg-blue-500 p-4 text-white flex justify-between">
        <nav className="w-full">
          {token ? (
            <div className="flex flex-row justify-between">
              <Link to="/add-feed" className="px-2 py-1 bg-blue-700 rounded">
                Add Feed
              </Link>
              <button
                onClick={() => setLogout(true)}
                className="px-2 py-1 bg-red-500 rounded"
              >
                Logout
              </button>
            </div>
          ) : (
            <Link to="/login" className="px-2 py-1 bg-blue-700 rounded">
              Login
            </Link>
          )}
        </nav>
      </header>
      <main className="flex-grow flex items-center justify-center">
        <Routes>
          <Route path="/" element={<Root />} />
          <Route
            path="/login"
            element={
              <Login setToken={handleSetToken} setLoading={setLoading} />
            }
          />
          <Route
            path="/protected"
            element={
              <Protected
                token={token}
                setLoading={setLoading}
                loading={loading}
              />
            }
          />
          <Route
            path="/add-feed"
            element={<AddFeed setLoading={setLoading} />}
          />
          <Route
            path="/edit-feed/:id"
            element={<EditFeed setLoading={setLoading} />}
          />
        </Routes>
      </main>
      {loading && (
        <div className="flex fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-50 items-center justify-center">
          <img src={loaderSVG} alt="loader" width={"50px"} height={"50px"} />
        </div>
      )}
    </div>
  );
}

export default App;
