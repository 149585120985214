// Auth

const tokenStorageKey = "jwt";

export const getToken = () => localStorage.getItem(tokenStorageKey);
export const setToken = (token) => localStorage.setItem(tokenStorageKey, token);
export const removeToken = () => localStorage.removeItem(tokenStorageKey);

// Date

export const convertTimestampToDatetimeLocal = (unixTimestamp) => {
  // Convert the Unix timestamp to a Date object
  const date = new Date(unixTimestamp * 1000);

  // Get the components of the date
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-indexed
  const day = ("0" + date.getDate()).slice(-2);
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);

  // Format the date as required for the datetime-local input type
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

  return formattedDate;
};
